.containerdata {
    /* background-image: url('E:\full_black\public\image\Render.png'); */
    display: block;
    float: left;
    background-size: 100%;
    background-size: cover;
    /* background-repeat: no-repeat; */
  
  }

  .container--blend {
    /* background-image: url('E:\full_black\public\image\windows.jpg'); */
    background-repeat: no-repeat;
    background-blend-mode: screen;
    
  }
  
  .landingImg{
    max-width: 100%;
    height:100%;
    background-size: cover;
    background-repeat: no-repeat;
   
  }

  /* .Landing-Page-title{
    color: black;
    text-align: center;
    font-size: 200px;
    mix-blend-mode: exclusion ;
  } */

  .renderimg{
    background-size: cover;
    width: 100%;  
    height: auto;
  }

  .landing-title{
    display: flex;
    justify-content: center;
    z-index: 999999999999;
    margin: -30%; 
    mix-blend-mode: exclusion;
    color: rgb(213, 228, 205);
    font-size: 200px;
    text-align: center;
  }
 