
video::-internal-media-controls-overlay-cast-button {
    display: none;
}

.video-column-list {
    background-color: #fff;
}

.top-arrow{
    position: fixed;
    width: 100%;
    text-align: center;
    background: #fff;
    font-size:12px;
    color:#000;
    font-weight:400;
    padding:25px 0;
    text-transform:capitalize;
    letter-spacing: 1px;
}
.bottom-close {
    position: fixed;
    bottom: 0;
    width:100%;
    text-align: center;
    background: #fff;
    color:#000;
    padding:25px 0;
}

.link-box {
    text-decoration: none;
}
.link-box h4{
    color: black;
}
.videolist-container {
    height: 100vh;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}
.videolist-container h4 {
    font-size: 30px;
    margin-bottom: 15px;
}
.videolist-container span {
    width: 100%;
    max-width: initial;
}
.loader-homepage{
    max-width: 20%;
}
.container{
    width: 100%;
    justify-items: center;
    display: grid;
}
.video-box-main {
    overflow: hidden;
}
.video-box{
    display: flex;
    padding-bottom:30px;
    margin-bottom:30px;
    border-bottom:1px solid #dedede;
    max-width:650px;
    overflow: hidden;
}
.video-box .video-img{
    width:30%;
    max-width:250px;
    height:150px;
}
.video-img a {
    display: block;
    width: 100%;
}
.video-box .video-img video{
    width:100%;
    height:150px;
    object-fit: cover;
}
.video-box .video-content{
    padding-left:25px;
    padding-top: 10px;
    text-align: left;
    width:100%;
}
.video-box .video-content h3{
    font-size: 33px;
    font-weight: 300;
    color:#000;
    margin:0;
    margin-bottom:5px;
    height: auto;
}
.video-box .video-content h4{
    text-transform: uppercase;
    font-weight: 700;
    font-size: 24px;
    line-height: 1em;
    margin:0;
    margin-bottom: 5px;
}
.video-box .video-content p{
    font-size:16px;
    color:#000;
    margin:0;
    text-align: left !important;
    text-align-last: left;
}
.video-box:hover .video-content h4 {
    font-style: italic;
}
.video-list-main {
    height: calc(100vh - 200px);
    overflow: auto;
    max-width: 650px;
    width: 92%;
}
::-webkit-scrollbar {
    width: 10px;
}
 
::-webkit-scrollbar-track {
    background-color: transparent;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: transparent; 
}
/* .video-list-main > div {
    overflow: hidden;
} */

@media screen and (max-width: 575px) {
    .video-box .video-img {
        width: 50%;
    }
    .video-box .video-content {
        width: 50%;
    }
    .video-box .video-content h4 {
        font-size: 20px;
    }
}