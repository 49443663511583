@font-face {
  font-family: 'polysansbulky_wide';
  src: url('font/polysans-bulkywide-webfont.woff2') format('woff2'),
       url('font/polysans-bulkywide-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
/* body {
  background-color: #000;
} */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.center-logo .slider-title-main h3 {
  overflow: inherit !important;
  font-size: 15vw !important;
  height: inherit !important;
  margin: 0 0 0 16px;
  mix-blend-mode: exclusion;
  letter-spacing: 1.5vw;
  text-transform: capitalize;
  background-color: transparent !important;
  position: fixed;
  bottom: 120px;
  z-index: 99;
  text-align: center;
  left: 0;
  right: 0;
}
.digital-agency {
  position: fixed;
  bottom: 135px;
  left: 0;
  right: 0;
  font-size: 34px;
  letter-spacing: 4px;
  text-transform: initial;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.bottom-close {
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
}



/* ------------------Link Effect--------------------------- */
.linkEffect {
  position: relative;
  overflow: hidden;
}
.linkEffect:hover::after {
  content: "";
  right: 0;
  bottom: 0;
  position: absolute;
  -webkit-animation: line_r_animation 1.5s linear;
  animation: line_r_animation 1.5s linear;
  height: 1px;
  background-color: #fff;
}

@keyframes line_r_animation {
  0%   {right: calc(2% + 100%); width: 0px;}
  35%   {right: calc(2% + 0px); width: 100%;}
  70%   {right: calc(2% + 0px); width: 0px;}
  100%   {right: calc(2% + 0px); width: 0px;}
}

.linkEffectClose.linkEffect:hover::after {
  background-color: #000;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.cssanimation {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.fadeInBottom { animation-name: fadeInBottom }
@keyframes fadeInBottom {
  from {
      opacity: 0;
      transform: translateY(200%);
  }
  to { opacity: 1 }
}

.cssanimation-2 {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.fadeInBottom-2 { animation-name: fadeInTop }
@keyframes fadeInTop {
  from {
      opacity: 1;
  }
  to { 
      opacity: 0;
      transform: translateY(200%);
   }
}