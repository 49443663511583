.about-main {
    background-color: #fff;
}
.aboutContent{
    font-size: 14px;
    font-weight: 300;
    margin-top: 40px;
    text-align: center;
}
p {
    /* text-align: justify !important; */
    text-align-last: center;
    font-weight: 300;
    font-size: 15px;
    color: #000;
    line-height: 22px;
}
.aboutContent p strong {
    font-weight: 500;
}
.title h1 {
    margin: 0;
    font-family: inherit;
}
.subTitle {
    font-weight: 100;
    font-family: inherit;
}
.logo-left {
    border-right: 1px solid #cfcfcf;
    padding-right: 30px;
    margin-right: 30px;
}
.logo-left h4, .logo-right h4 {
    margin-top: 0;
    font-weight: 400;
    font-size: 14px;
}

.about-img{
    max-width: 60px;
}
.logo-left {
    text-align: right;
}
.logo-right {
    text-align: left;
}

@media screen and (max-height: 700px) {
    .about-main {
        height: auto !important;
        margin-top: 20px;
    }
    .about-main .bottom-close {
        bottom: 0;
        margin-top: 0;
    }
    .bottom-logo {
        margin-bottom: 70px;
    }
    .logo-list {
        display: block !important;
    }
    .logo-left ul, .logo-right ul {
        display: block;
    }
    .logo-left ul li, .logo-right ul li {
        margin: 0;
        padding: 0;
    }
    .logo-left h4, .logo-right h4 {
        font-size: 10px !important;
    }
}
