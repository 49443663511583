  /* h3{
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
  } */

.home-layout{
    background-color: black;
}


.loader-homepage{
    max-width: 20%;
}
.home-loader-container {
    height: 100vh;
    background-color: black;
    position: relative;
}
.home-loader-box {
    width: 200px;
    text-align: center;
    margin: 0 auto 0;
    top: 42%;
    position: absolute;
    left: 0;
    right: 0;
    transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
}
.home-loader-box-d {
    top: 5%;
}
.home-loader-container h4 {
    font-size: 30px;
    margin: 0 0 15px;
}
.home-loader-container span {
    width: 100%;
    max-width: initial;
}

/* word transition */

.slider-title h3 {
    font-size: 20px;
    overflow :hidden;
    height: 25px;
    font-family: 'polysansbulky_wide';
    margin-top: -6px;
}
.navbar-main {
    overflow: hidden;
}
.navbar-main > div {
    overflow: hidden;
    height: 45px;
}

.slider-title-main {
    overflow: hidden;
    height: 27px;
}


.letter {
    display: inline-block;
    transition: transform .6s cubic-bezier(.76,0,.24,1);
    transition-property: transform;
    transition-duration: 0.6s;
    transition-timing-function: cubic-bezier(0.76, 0, 0.24, 1);
    mix-blend-mode: exclusion;

}

.letter:first-child {
    transition-delay: 0s;
}

.letter:nth-child(2) {
    transition-delay: 15ms;
}

.letter:nth-child(3) {
    transition-delay: .03s;
}

.letter:nth-child(4) {
    transition-delay: 45ms;
}

.letter:nth-child(5) {
    transition-delay: .06s;
}

.letter:nth-child(6) {
    transition-delay: 75ms;
}

.letter:nth-child(7) {
    transition-delay: .09s;
}

.letter:nth-child(8) {
    transition-delay: .105s;
}

.letter:nth-child(9) {
    transition-delay: .12s;
}
.letter:nth-child(10) {
    transition-delay: .135s;
}

/* .letter:nth-child(10) {
    transition-delay: .135s;
} */
h3.play .letter, h3:hover .letter {
    transform: translateY(-100%);
}

.block {
    display: block;
}

.block:last-child {
    color: #ffb400;
}


.footer {
    position: fixed;
    bottom: 30px;
    left: 0;
    display: flex;
    justify-content: space-between;
    width: 93%;
    margin: 0 auto;
    align-items: center;
    right: 0;
}
.footer button {
    color: #fff;
    font-weight: 100;
}


.slick-initialized .slick-slide {
    position: relative;
}
.slider-text-main {
    position: absolute;
    bottom: 22px;
    text-align: center;
    left: 0;
    right: 0;
}
.slider-title {
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    mix-blend-mode: exclusion;
    background-color: transparent !important;
}
.slider-sub-title {
    color: #fff;
    font-size: 12px;
    font-style: italic;
    margin-top: 10px;
    mix-blend-mode: exclusion;
    text-transform: capitalize;
}

/* .progres-bar{
    bottom: 10%;
    margin: 0;
    margin-bottom: -15%;
    color: white;
    background-color: aqua;
} */

/* .progress{
    margin: -64px;
    bottom: 20px;
    z-index: 9999;
    /* margin-bottom: -18px; */
    /* background-color: aqua;
    color: aqua;
}  */

/* ----------------arrows hover------------------------ */

/* max-width: 320px;
    min-width: 3px;
    height: 1px;
    background-color: #fff;
    will-change: width;
    transition: .2s linear;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
} */
.about-text {
    width: 61px;
}

.center-logo {
    /* position: absolute; */
    left: 0;
    right: 0;
    text-align: center;
    bottom: 10%;
    margin: 0 auto 0 !important;
    height: 0 !important;
}
.center-logo .slider-title-main {
    height: inherit;
    overflow: inherit;
    display: inline-block;
}
.center-logo .slider-title-main .block {
    text-transform: initial;
}
.slick-prev, .slick-next {
    top: 46%;
}
.slick-slider svg {
    mix-blend-mode: exclusion;
}



@media screen and (max-width: 991px) {
    .center-logo {
        margin: -72px auto 0;
        height: 144px;
    }
    .center-logo .slider-title-main h3 {
        font-size: 100px;
        height: 144px;
    }
    .digital-agency {
        bottom: 110px;
    }
}

@media screen and (max-width: 767px) {
    .slick-slider .slick-next {
        margin-right: 0;
        right: 20px;
    }
    .slick-slider .slick-next:hover {
        right: 20px;
    }
    .slick-slider .slick-prev {
        margin-left: 0;
        left: 20px;
    }
    .slick-slider .slick-prev:hover {
        left: 20px;
    }
    .footer-center {
        position: absolute;
        bottom: 50px;
        width: 220px;
        margin: 0 auto;
        left: 0;
        right: 0;
    }
    .slider-text-main {
        bottom: 56px;
    }
    .center-logo {
        margin: -57px auto 0;
        height: 115px;
        bottom: 20%;
    }
    .center-logo .slider-title-main h3 {
        font-size: 80px;
        height: 115px;
        bottom: 160px;
    }
    .digital-agency {
        bottom: 140px;
    }
}

@media screen and (max-width: 575px) {
    .center-logo {
        margin: -26px auto 0;
        height: 52px;
    }
    .center-logo .slider-title-main h3 {
        font-size: 36px;
        height: 52px;
        margin-left: 5px;
    }
    .digital-agency {
        font-size: 20px;
    }
}

