.contact-main {
  background-color: #fff;
}
div .css-1x51dt5-MuiInputBase-input-MuiInput-input {
  font-size: x-small;
}
input {
  font-size: x-small !important;
  font-weight: 400 !important;
}

.contact-heading {
  text-transform: uppercase;
  margin-top: 15px;
  text-align: center;
  font-weight: 200;
  font-size: 15px;
  /* text-decoration: none; */
}

.submit-button {
  color: #000;
  font-size: 12px;
  font-weight: 700;
  float: right;
  text-transform: uppercase;
  outline: none;
  border: none;
  font-family: inherit;
}

.social-img {
  max-width: 20px;
}
.linkEffectClose {
  left: 0;
  right: 0;
}

textarea {
  resize: none;
}
textarea::-webkit-input-placeholder {
  /* Edge */
  color: #b6b6b6;
}

textarea:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b6b6b6;
}

textarea::placeholder {
  color: #b6b6b6;
}

.title {
  overflow: hidden;
  font-family: inherit;
}
.social-icons a {
  width: 20px;
  height: 20px;
  display: block;
  position: relative;
}
.social-icons a img {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
  transform: translateY(0px);
  transition: 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.social-icons a img:last-child {
  position: absolute;
  bottom: -25px;
  left: 0;
}
.social-icons a:hover img:last-child {
  position: absolute;
  bottom: -120%;
}
.social-icons a:hover img {
  transform: translateY(-24px);
  transition: 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.MuiInput-root:hover::before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.87) !important;
}
.MuiInput-root::after {
  display: none !important;
}
textarea:hover,
textarea:focus {
  border-bottom: 1px solid #000;
}
.contact-heading span {
  color: #000 !important;
  font-size: 15px !important;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
}
.contact-heading p {
  margin: 0 0 6px;
}

@media screen and (max-width: 767px) {
  .contact-form {
    width: auto !important;
    padding: 0 !important;
  }
}
@media screen and (max-height: 700px) {
  .contact-main {
    height: auto !important;
    margin-top: 50px;
  }
  .contact-main > div {
    height: auto !important;
    width: 100%;
  }

  .contact-main .bottom-close {
    bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
  .contact-form {
    margin-bottom: 70px;
  }
}
